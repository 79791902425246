import React, { useState } from 'react';

const PDFUpload = ({ onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setLoading(true);
      try {
        await onUpload(selectedFile);
      } catch (error) {
        console.error('Upload failed:', error);
        alert('Failed to upload file. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <div className="max-w-md mx-auto shadow-lg rounded-lg">
      <div className="bg-white border-2 border-blue-500 rounded-lg p-6">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <svg
              className="animate-spin h-10 w-10 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.042.77 3.902 2.051 5.291l2.049-2.291z"
              ></path>
            </svg>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-4">Upload Your Document</h2>
            <div className="text-sm text-gray-600 mb-4">
              <p><strong>Supported files:</strong> PDF, DOCX, TXT</p>
            </div>
            <div
              className={`border-2 border-dashed rounded-lg p-4 mb-4 flex flex-col items-center justify-center cursor-pointer hover:border-blue-400 ${dragging ? 'border-blue-400' : 'border-gray-300'}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <input
    type="file"
    onChange={handleFileChange}
    className="hidden"
    id="file-upload"
    accept=".pdf,.docx,.txt,.doc,.text/plain,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  />

              <label htmlFor="file-upload" className="text-center flex flex-col items-center">
                <svg
                  className="w-12 h-12 mb-3 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                <span className="text-lg text-gray-600">
                  Drag & Drop or Choose file to upload
                </span>
              </label>
            </div>
            {selectedFile && (
    <div className="bg-gray-100 p-3 rounded-lg mb-4 flex items-center justify-between">
      <span className="truncate max-w-full" title={selectedFile.name}>
        {selectedFile.name.length > 20 ? `${selectedFile.name.slice(0, 20)}...` : selectedFile.name}
      </span>
      <button
        className="text-red-500 hover:text-red-700"
        onClick={() => setSelectedFile(null)}
      >
        ✕
      </button>
    </div>
  )}

            <button
              onClick={handleUpload}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg w-full hover:bg-blue-600 transition duration-200"
            >
              Get Clarity
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PDFUpload;
