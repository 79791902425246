import React, { useState, useEffect } from 'react';
import CheckoutButton from '../components/CheckoutButton';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function PricingPage() {
  const { currentUser } = useAuth();
  const [customerEmail, setCustomerEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomerIdAndCheckSubscription = async () => {
      if (currentUser) {
        setCustomerEmail(currentUser.email);

        try {
          const response = await fetch('https://dashboard.useclarity.app:3001/check-subscription-status', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: currentUser.email }),
          });

          if (response.ok) {
            const { activeSubscription } = await response.json();
            if (activeSubscription) {
              navigate('/dashboard');
            } else {
              setLoading(false);
            }
          } else {
            console.error('Failed to check subscription status');
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching subscription status:', error);
          setLoading(false);
        }
      }
    };

    fetchCustomerIdAndCheckSubscription();
  }, [currentUser, navigate]);

  if (loading) {
    return <p>Loading your subscription information...</p>;
  }

  return (
    <div className="pricing-container">
      <h1 className="pricing-header">Choose Your Plan</h1>
      <div className="how-credits-work">
        <p>When you subscribe to a plan, your credits will be topped up each month by the quantity listed below. An analysis with Clarity costs 1 credit, and drafting a negotiation letter also costs 1 credit. However, using the draft letter feature is optional.</p><br/>
      </div>
      {customerEmail ? (
        <div className="pricing-cards">
          <div className="pricing-card">
            <h2>Starter Plan</h2>
            <p className="credits">6x Monthly Credits</p>
            <p className="features">Automated Negotiation Letters</p>
            <h3>$9/month</h3>
            <CheckoutButton priceId="price_1PlrheKHEcJeBVEJDHD5VH3c" customerId={customerEmail} />
            <p className="cancel-label">Cancel Anytime</p>
          </div>
          <div className="pricing-card">
            <h2>Advanced Plan</h2>
            <p className="credits">24x Monthly Credits</p>
            <p className="features">Automated Negotiation Letters</p>
            <h3>$29/month</h3>
            <CheckoutButton priceId="price_1Pls8JKHEcJeBVEJC2eHNP2V" customerId={customerEmail} />
            <p className="cancel-label">Cancel Anytime</p>
          </div>
          <div className="pricing-card">
            <h2>Pro Plan</h2>
            <p className="credits">50x Monthly Credits</p>
            <p className="features">Automated Negotiation Letters</p>
            <h3>$49/month</h3>
            <CheckoutButton priceId="price_1PlrgeKHEcJeBVEJBu82monC" customerId={customerEmail} />
            <p className="cancel-label">Cancel Anytime</p>
          </div>
        </div>
      ) : (
        <p>Loading your customer information...</p>
      )}
      <div className="disclaimer">
        <p>
          By signing up to a plan and/or using Clarity you have read and accept our legal disclaimer <a href="https://docs.google.com/document/d/16F_VJANLKGXeRO_I9iF6jWSpklP2YrfyIW-3_MC1lcs/edit?usp=sharing" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      </div>
    </div>
  );
}

export default PricingPage;
