import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { FaCog, FaTrashAlt } from 'react-icons/fa';

function PreviousDocuments() {
  const { currentUser } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [searchText, setSearchText] = useState('');
  const db = getFirestore();

  useEffect(() => {
    const fetchDocuments = async () => {
      if (!currentUser) return;

      const userRef = doc(db, 'users', currentUser.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        if (userData.creditHistory) {
          const sortedDocuments = userData.creditHistory.sort((a, b) => b.timestamp - a.timestamp);
          setDocuments(sortedDocuments);
        }
      }
    };

    fetchDocuments();
  }, [currentUser, db]);

  const handleRename = async (index) => {
    const newFileName = prompt('Enter new file name:');
    if (newFileName) {
      const updatedDocuments = [...documents];
      updatedDocuments[index].fileName = newFileName;
      setDocuments(updatedDocuments);
      
      // Update in Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        creditHistory: updatedDocuments
      });
    }
  };

  const handleDelete = async (index) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      const updatedDocuments = documents.filter((_, i) => i !== index);
      setDocuments(updatedDocuments);

      // Update in Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        creditHistory: updatedDocuments
      });
    }
  };

  const filteredDocuments = documents.filter(doc => 
    doc.fileName.toLowerCase().includes(searchText.toLowerCase())
  );

  const stripFileExtension = (fileName) => {
    return fileName.replace(/\.(pdf|docx?|txt)$/i, '');
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Previous Documents</h1>
      <input
        type="text"
        placeholder="Search documents..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className="w-full p-2 mb-4 border rounded-lg"
      />
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <ul className="divide-y divide-gray-200">
          <li className="flex justify-between items-center p-4 bg-gray-100">
            <span className="text-lg font-medium">File Name</span>
            <span className="text-lg font-medium text-right w-1/4">Date Added</span>
          </li>
          {filteredDocuments.map((doc, index) => (
            <li key={index} className="p-4 hover:bg-gray-100 cursor-pointer flex justify-between items-center">
              <Link to={`/analysis/${doc.timestamp}`} className="flex-grow">
                <span className="text-lg font-medium">{stripFileExtension(doc.fileName)}</span>
              </Link>
              <div className="flex items-center space-x-2">
                <button onClick={() => handleRename(index)} className="text-blue-500 hover:text-blue-700">
                  <FaCog />
                </button>
                <button onClick={() => handleDelete(index)} className="text-red-500 hover:text-red-700">
                  <FaTrashAlt />
                </button>
                <span className="text-sm text-gray-500 text-right">{new Date(doc.timestamp * 1000).toLocaleDateString()}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PreviousDocuments;
