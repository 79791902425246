import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const IssueItem = ({ issue, severityClass, isLastItem }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`issue-item ${severityClass} ${isLastItem ? 'last-item' : ''}`}>
            <div className="issue-header" onClick={toggleDropdown}>
                <span className="issue-title truncate">{issue.title}</span>
                <button>
                    {isOpen ? '−' : '+'}
                </button>
            </div>
            {isOpen && (
                <div className={`issue-description show`}>
                    {issue.description}
                </div>
            )}
        </div>
    );
};

IssueItem.propTypes = {
    issue: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired,
    severityClass: PropTypes.string.isRequired,
    isLastItem: PropTypes.bool.isRequired
};

export default IssueItem;
