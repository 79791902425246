import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import IssueList from './IssueList';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getFirestore, doc, getDoc, updateDoc, increment, arrayUnion } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import { FaPlus, FaFilePdf, FaPenFancy } from 'react-icons/fa'; // Import icons

function AnalysisDetail() {
  const { id } = useParams();
  const { userData, setUserData } = useUser();
  const [showTop5, setShowTop5] = useState(true); // Toggle state
  const [loading, setLoading] = useState(false);
  const [draftLetter, setDraftLetter] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const db = getFirestore();

  const document = userData?.creditHistory.find(doc => doc.timestamp.toString() === id);

  if (!document) {
    return <div>Document not found.</div>;
  }

  const { fileName, analysisResults } = document;

  const exportPDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 10; // Adjust the line height as needed
    let currentY = 70;

    // Add logo
    doc.addImage('/favicon-256x256.png', 'PNG', margin, 10, 30, 30);

    // Add title
    const title = `${fileName} - useclarity.app`;
    doc.setFontSize(18);
    doc.text(title, margin, 50, { maxWidth: maxLineWidth });

    // Function to handle adding text with page breaks
    const addTextWithPageBreak = (text, yPosition, lineHeight) => {
      const lines = doc.splitTextToSize(text, maxLineWidth);
      lines.forEach((line) => {
        if (yPosition + lineHeight > pageHeight - 20) { // 20 for some margin at the bottom
          doc.addPage();
          yPosition = margin; // Reset to top of new page
        }
        doc.text(line, margin, yPosition);
        yPosition += lineHeight;
      });
      return yPosition;
    };

    // Iterate through the analysis sections and add text with page breaks
    const sections = [
      { title: 'Financial Liabilities and Costs', issues: analysisResults.financialLiabilities },
      { title: 'Legal and Compliance Risks', issues: analysisResults.legalComplianceRisks },
      { title: 'Operational and Service Impact', issues: analysisResults.operationalServiceImpact },
      { title: 'Control, Flexibility, and Long-Term Commitments', issues: analysisResults.controlFlexibility },
      { title: 'Information, Transparency, and Dispute Resolution', issues: analysisResults.informationTransparency },
    ];

    sections.forEach((section) => {
      if (section.issues.length > 0) {
        doc.setFontSize(14);
        currentY = addTextWithPageBreak(section.title, currentY, lineHeight);
        section.issues.forEach((issue) => {
          doc.setFontSize(12);
          doc.setFont('Helvetica', 'bold');
          currentY = addTextWithPageBreak(issue.title, currentY, lineHeight);
          doc.setFont('Helvetica', 'normal');
          currentY = addTextWithPageBreak(issue.description, currentY, lineHeight);
        });
      }
    });

    // Add footer
    doc.setFontSize(10);
    doc.text('Analysis by useclarity.app', margin, currentY + 10);

    doc.save(`${fileName}_analysis.pdf`);
  };


  const draftCounterLetter = async () => {
    if (window.confirm('Using the draft negotiation letter feature will cost 1x credit, do you want to proceed?')) {
      setLoading(true);
      try {
        const userId = currentUser.uid;
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
          throw new Error('User data not found.');
        }

        const response = await fetch('https://dashboard.useclarity.app:5000/api/draft-letter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ analysis: analysisResults })
        });

        if (!response.ok) {
          throw new Error('Failed to draft counter letter');
        }

        const data = await response.json();
        setDraftLetter(data.letter);

        await updateDoc(userRef, {
          credits: increment(-1),
          creditHistory: arrayUnion({
            fileName,
            timestamp: Math.floor(Date.now() / 1000),
            creditsUsed: 1,
            draftLetter: data.letter
          })
        });

        setUserData((prevUserData) => ({
          ...prevUserData,
          credits: prevUserData.credits - 1,
          creditHistory: [
            ...(prevUserData.creditHistory ? prevUserData.creditHistory : []),
            {
              fileName,
              timestamp: Math.floor(Date.now() / 1000),
              creditsUsed: 1,
              draftLetter: data.letter,
            },
          ],
        }));

      } catch (error) {
        console.error('Error drafting letter:', error);
        alert('Error drafting letter');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h3 className="text-2xl font-bold mb-4">Analysis for {fileName}</h3>

      {/* Buttons and Toggle Row */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white py-2 px-4 rounded flex items-center justify-center"
            onClick={() => navigate('/dashboard')} // Navigate back to Dashboard to upload another document
          >
            <FaPlus className="md:mr-2" />
            <span className="hidden md:inline">Upload Document</span>
          </button>
          <button 
            className="bg-green-500 text-white py-2 px-4 rounded flex items-center justify-center"
            onClick={exportPDF}
          >
            <FaFilePdf className="md:mr-2" />
            <span className="hidden md:inline">Analysis PDF</span>
          </button>
          <button 
            className={`bg-yellow-500 text-white py-2 px-4 rounded flex items-center justify-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={draftCounterLetter}
            disabled={loading}
          >
            <FaPenFancy className="md:mr-2" />
            {loading ? (
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
            ) : <span className="hidden md:inline">Counter Letter</span>}
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-2">Top 5 Concerns Only</span>
          <label className="toggle-switch">
            <input 
              type="checkbox" 
              checked={showTop5} 
              onChange={() => setShowTop5(!showTop5)} 
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>

      {/* Render Draft Letter */}
      {draftLetter && (
        <div className="mt-4 bg-gray-100 p-4 rounded">
          <h3 className="font-bold mb-2">Draft Counter Letter:</h3>
          <p>{draftLetter.split('\n').map((line, index) => (
            <span key={index}>{line}<br/></span>
          ))}</p>
        </div>
      )}

      {/* Render Analysis Results */}
      {analysisResults.financialLiabilities?.length > 0 && (
        <IssueList 
          issues={analysisResults.financialLiabilities} 
          title="Financial Liabilities and Costs" 
          severityClass="financial-liabilities" 
          showTop5={showTop5} 
        />
      )}
      {analysisResults.legalComplianceRisks?.length > 0 && (
        <IssueList 
          issues={analysisResults.legalComplianceRisks} 
          title="Legal and Compliance Risks" 
          severityClass="legal-compliance-risks" 
          showTop5={showTop5} 
        />
      )}
      {analysisResults.operationalServiceImpact?.length > 0 && (
        <IssueList 
          issues={analysisResults.operationalServiceImpact} 
          title="Operational and Service Impact" 
          severityClass="operational-service-impact" 
          showTop5={showTop5} 
        />
      )}
      {analysisResults.controlFlexibility?.length > 0 && (
        <IssueList 
          issues={analysisResults.controlFlexibility} 
          title="Control, Flexibility, and Long-Term Commitments" 
          severityClass="control-flexibility" 
          showTop5={showTop5} 
        />
      )}
      {analysisResults.informationTransparency?.length > 0 && (
        <IssueList 
          issues={analysisResults.informationTransparency} 
          title="Information, Transparency, and Dispute Resolution" 
          severityClass="information-transparency" 
          showTop5={showTop5} 
        />
      )}
    </div>
  );
}

export default AnalysisDetail;
