import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Import Firestore

const firebaseConfig = {
  apiKey: "AIzaSyA5VhXrmYS6yE5H6j8k9TKoJbMy6wzkYZo",
  authDomain: "clarity-e2e36.firebaseapp.com",
  projectId: "clarity-e2e36",
  storageBucket: "clarity-e2e36.appspot.com",
  messagingSenderId: "469842045633",
  appId: "1:469842045633:web:3decd24b518b48fba14240"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app); // Export Firestore

export default app;
