import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useUser } from '../UserContext';
import { FaTachometerAlt, FaFileAlt, FaCoins, FaSignOutAlt, FaUser } from 'react-icons/fa'; // Import icons

function NavBar() {
  const { currentUser, logout } = useAuth();
  const { userData, setUserData } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    const fetchUserCredits = async () => {
      if (currentUser) {
        try {
          const response = await fetch('https://dashboard.useclarity.app:3001/get-user-credits', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: currentUser.uid }),
          });

          if (response.ok) {
            const data = await response.json();
            setUserData((prevData) => ({
              ...prevData,
              credits: data.credits,
            }));
          } else {
            console.error('Failed to fetch user credits');
          }
        } catch (error) {
          console.error('Error fetching user credits:', error);
        }
      }
    };

    fetchUserCredits();
  }, [currentUser, setUserData]);

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/login";
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  return (
    <nav className="bg-white p-4 font-inter border-b border-[#F3F3F3]" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center text-[#3B82F6] text-xl">
          <img src="/favicon-256x256.png" alt="Clarity Logo" style={{ width: '32px', height: '32px' }} />
          <span className="ml-2 font-light text-gray-800">Clarity</span>
        </Link>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-[#3B82F6] focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
          </button>
        </div>
        <div className={`md:flex ${isOpen ? "block" : "hidden"} w-full md:w-auto`}>
          <div className="flex flex-col md:flex-row md:items-center">
            {currentUser ? (
              <>
                <Link to="/dashboard" className="nav-link text-[#3B82F6] bg-[#F3F4F6] px-4 py-2 flex items-center">
                  <FaTachometerAlt className="mr-2" /> Dashboard
                </Link>
                <Link to="/previous-documents" className="nav-link text-[#3B82F6] bg-[#F3F4F6] px-4 py-2 flex items-center">
                  <FaFileAlt className="mr-2" /> My Documents
                </Link>
                <Link to="https://billing.stripe.com/p/login/14k8xNcQL59kd20fYY" className="nav-link text-[#3B82F6] bg-[#F3F4F6] px-4 py-2 flex items-center">
                  <FaCoins className="mr-2" /> Credits: {userData?.credits || 0}
                </Link>
                <button
                  onClick={handleLogout}
                  className="text-white bg-red-500 hover:bg-red-700 px-4 py-2 rounded flex items-center justify-center"
                  style={{ height: '100%', padding: '0 12px' }}
                >
                  <FaSignOutAlt style={{ fontSize: '16px' }} />
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="text-[#3B82F6] bg-[#F3F4F6] px-4 py-2 flex items-center">
                  <FaUser className="mr-2" /> Login
                </Link>
                <Link to="/signup" className="text-[#3B82F6] bg-[#F3F4F6] px-4 py-2 flex items-center">
                  <FaUser className="mr-2" /> Signup
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
