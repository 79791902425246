import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PDFUpload from './PDFUpload';
import IssueList from './IssueList';
import { useAuth } from '../AuthContext';
import { useUser } from '../UserContext';  
import { getFirestore, doc, getDoc, setDoc, updateDoc, increment, arrayUnion } from 'firebase/firestore';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FaPlus, FaFilePdf, FaPenFancy } from 'react-icons/fa'; // Import icons

function Dashboard() {
  const { setUserData, userData } = useUser();  // Get userData from UserContext
  const [analysis, setAnalysis] = useState(null);
  const [fileName, setFileName] = useState(''); // Set fileName state
  const [loading, setLoading] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(true);
  const [draftLetter, setDraftLetter] = useState('');
  const [showTop5, setShowTop5] = useState(true); // Toggle state
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();

  // Utility function to get credits based on plan
  const getCreditsBasedOnPlan = (planId) => {
    switch (planId) {
      case 'starter':
        return 6;
      case 'advanced':
        return 24;
      case 'pro':
        return 50;
      default:
        return 0;
    }
  };

  // Fetch user credits from Firebase or create a new user document if not existing
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            // User exists, update the context with their data
            const userData = userSnap.data();
            setUserData(userData);
          } else {
            // User does not exist, create a new document with default credits based on their plan
            const planId = 'starter'; // Replace this with logic to get the user's plan ID
            const initialCredits = getCreditsBasedOnPlan(planId); 
            await setDoc(userRef, {
              credits: initialCredits,
              email: currentUser.email,
              subscriptionStatus: 'active',
              creditHistory: []
            });
            setUserData({
              credits: initialCredits,
              email: currentUser.email,
              subscriptionStatus: 'active',
              creditHistory: []
            });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [currentUser, setUserData, db]);

  const getFileNameWithoutExtension = (fileName) => {
    return fileName.replace(/\.[^/.]+$/, "");
  };

  const handleFileUpload = async (file) => {
    setLoading(true);
  
    try {
      const userId = currentUser.uid;
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        throw new Error('User data not found.');
      }

      const userData = userSnap.data();

      if (!userData.creditHistory) {
        userData.creditHistory = [];
      }

      let currentCredits = userData.credits || 0;

      if (currentCredits <= 0) {
        alert('You have no credits left. Please upgrade your plan.');
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);  // Updated key to 'file'

      const response = await fetch('https://dashboard.useclarity.app:5000/api/analyze', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setAnalysis({ ...data, fileName: file.name });
      setFileName(file.name); // Set the fileName state here

      await updateDoc(userRef, {
        credits: increment(-1),
        creditHistory: arrayUnion({
          fileName: file.name,
          timestamp: Math.floor(Date.now() / 1000),
          creditsUsed: 1,
          analysisResults: data
        })
      });

      setUserData((prevUserData) => ({
        ...prevUserData,
        credits: prevUserData.credits - 1,
        creditHistory: [
          ...(prevUserData.creditHistory ? prevUserData.creditHistory : []),  // Fallback to an empty array
          {
            fileName: file.name,
            timestamp: Math.floor(Date.now() / 1000),
            creditsUsed: 1,
            analysisResults: data,
          },
        ],
      }));

      setUploadVisible(false);
    } catch (error) {
      console.error('Failed to fetch:', error);
      alert('Failed to fetch');
    } finally {
      setLoading(false);
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxLineWidth = pageWidth - margin * 2;
    const lineHeight = 10; // Adjust the line height as needed
    let currentY = 70;

    // Add logo
    doc.addImage('/favicon-256x256.png', 'PNG', margin, 10, 30, 30);

    // Add title
    const title = `${fileName} - useclarity.app`;
    doc.setFontSize(18);
    doc.text(title, margin, 50, { maxWidth: maxLineWidth });

    // Function to handle adding text with page breaks
    const addTextWithPageBreak = (text, yPosition, lineHeight) => {
      const lines = doc.splitTextToSize(text, maxLineWidth);
      lines.forEach((line) => {
        if (yPosition + lineHeight > pageHeight - 20) { // 20 for some margin at the bottom
          doc.addPage();
          yPosition = margin; // Reset to top of new page
        }
        doc.text(line, margin, yPosition);
        yPosition += lineHeight;
      });
      return yPosition;
    };

    // Iterate through the analysis sections and add text with page breaks
    const sections = [
      { title: 'Financial Liabilities and Costs', issues: analysis.financialLiabilities },
      { title: 'Legal and Compliance Risks', issues: analysis.legalComplianceRisks },
      { title: 'Operational and Service Impact', issues: analysis.operationalServiceImpact },
      { title: 'Control, Flexibility, and Long-Term Commitments', issues: analysis.controlFlexibility },
      { title: 'Information, Transparency, and Dispute Resolution', issues: analysis.informationTransparency },
    ];

    sections.forEach((section) => {
      if (section.issues.length > 0) {
        doc.setFontSize(14);
        currentY = addTextWithPageBreak(section.title, currentY, lineHeight);
        section.issues.forEach((issue) => {
          doc.setFontSize(12);
          doc.setFont('Helvetica', 'bold');
          currentY = addTextWithPageBreak(issue.title, currentY, lineHeight);
          doc.setFont('Helvetica', 'normal');
          currentY = addTextWithPageBreak(issue.description, currentY, lineHeight);
        });
      }
    });

    // Add footer
    doc.setFontSize(10);
    doc.text('Analysis by useclarity.app', margin, currentY + 10);

    doc.save(`${getFileNameWithoutExtension(fileName)}_analysis.pdf`);
  };

  const draftCounterLetter = async () => {
    if (window.confirm('Using the draft negotiation letter feature will cost 1x credit, do you want to proceed?')) {
      setLoading(true);
      try {
        const userId = currentUser.uid;
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
          throw new Error('User data not found.');
        }

        const userData = userSnap.data();
        let currentCredits = userData.credits || 0;

        if (currentCredits <= 0) {
          alert('You have no credits left. Please upgrade your plan.');
          setLoading(false);
          return;
        }

        const response = await fetch('https://dashboard.useclarity.app:5000/api/draft-letter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ analysis })
        });

        const data = await response.json();
        setDraftLetter(data.letter);

        await updateDoc(userRef, {
          credits: increment(-1),
          creditHistory: arrayUnion({
            fileName: analysis.fileName,
            timestamp: Math.floor(Date.now() / 1000),
            creditsUsed: 1,
            draftLetter: data.letter
          })
        });

        setUserData((prevUserData) => ({
          ...prevUserData,
          credits: prevUserData.credits - 1,
          creditHistory: [
            ...(prevUserData.creditHistory ? prevUserData.creditHistory : []),
            {
              fileName: analysis.fileName,
              timestamp: Math.floor(Date.now() / 1000),
              creditsUsed: 1,
              draftLetter: data.letter,
            },
          ],
        }));

      } catch (error) {
        console.error('Error drafting letter:', error);
        alert('Error drafting letter');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      {uploadVisible ? (
        <PDFUpload onUpload={handleFileUpload} />
      ) : (
        <>
          <div className="flex flex-wrap justify-between items-center mb-4">
            <div className="flex space-x-2">
              <button 
                className="bg-blue-500 text-white py-2 px-4 rounded flex items-center justify-center"
                onClick={() => {
                  setAnalysis(null);
                  setUploadVisible(true);
                  setDraftLetter(''); // Clear the draft letter when uploading another document
                }}
              >
                <FaPlus className="md:mr-2" />
                <span className="hidden md:inline">Upload Document</span>
              </button>
              <button 
                className="bg-green-500 text-white py-2 px-4 rounded flex items-center justify-center"
                onClick={exportPDF}
              >
                <FaFilePdf className="md:mr-2" />
                <span className="hidden md:inline">Analysis PDF</span>
              </button>
              <button 
                className={`bg-yellow-500 text-white py-2 px-4 rounded flex items-center justify-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={draftCounterLetter}
                disabled={loading}
              >
                <FaPenFancy className="md:mr-2" />
                {loading ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>
                ) : <span className="hidden md:inline">Counter Letter</span>}
              </button>
            </div>

            <div className="flex items-center mt-4 md:mt-0">
              <span className="mr-2">Top 5 Concerns Only</span>
              <label className="toggle-switch">
                <input 
                  type="checkbox" 
                  checked={showTop5} 
                  onChange={() => setShowTop5(!showTop5)} 
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>

          {draftLetter && (
            <div className="mt-4 bg-gray-100 p-4 rounded">
              <h3 className="font-bold mb-2">Draft Counter Letter:</h3>
              <p>{draftLetter.split('\n').map((line, index) => (
                <span key={index}>{line}<br/></span>
              ))}</p>
            </div>
          )}

          {analysis && (
            <div className="mt-4">
              <h2 className="text-xl font-bold text-left">{getFileNameWithoutExtension(analysis.fileName)}</h2>
              <div className="mt-4">
                {analysis.financialLiabilities?.length > 0 && (
                  <IssueList 
                    issues={analysis.financialLiabilities} 
                    title="Financial Liabilities and Costs" 
                    severityClass="financial-liabilities" 
                    showTop5={showTop5} 
                  />
                )}
                {analysis.legalComplianceRisks?.length > 0 && (
                  <IssueList 
                    issues={analysis.legalComplianceRisks} 
                    title="Legal and Compliance Risks" 
                    severityClass="legal-compliance-risks" 
                    showTop5={showTop5} 
                  />
                )}
                {analysis.operationalServiceImpact?.length > 0 && (
                  <IssueList 
                    issues={analysis.operationalServiceImpact} 
                    title="Operational and Service Impact" 
                    severityClass="operational-service-impact" 
                    showTop5={showTop5} 
                  />
                )}
                {analysis.controlFlexibility?.length > 0 && (
                  <IssueList 
                    issues={analysis.controlFlexibility} 
                    title="Control, Flexibility, and Long-Term Commitments" 
                    severityClass="control-flexibility" 
                    showTop5={showTop5} 
                  />
                )}
                {analysis.informationTransparency?.length > 0 && (
                  <IssueList 
                    issues={analysis.informationTransparency} 
                    title="Information, Transparency, and Dispute Resolution" 
                    severityClass="information-transparency" 
                    showTop5={showTop5} 
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
