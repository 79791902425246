import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const PrivateRoute = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [hasPaid, setHasPaid] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://dashboard.useclarity.app:3001/check-subscription-status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: currentUser.email }),
        });

        if (response.ok) {
          const { activeSubscription } = await response.json();
          setHasPaid(activeSubscription);
        } else {
          console.error('Failed to check subscription status');
          setHasPaid(false);
        }
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        setHasPaid(false);
      } finally {
        setLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!hasPaid) {
    return <Navigate to="/pricing" state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
