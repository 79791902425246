import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import PricingPage from './pages/PricingPage'; 
import PreviousDocuments from './components/PreviousDocuments';
import AnalysisDetail from './components/AnalysisDetail';
import ForgotPassword from './components/ForgotPassword'; // Import the Forgot Password component
import { AuthProvider } from './AuthContext';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <AuthProvider>
        <NavBar />
        <div className="container mx-auto p-4">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Route for forgot password */}

            {/* Private Routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/previous-documents" element={<PreviousDocuments />} />
              <Route path="/analysis/:id" element={<AnalysisDetail />} />
              <Route path="/" element={<Dashboard />} />
            </Route>

            {/* Redirect any unknown paths */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
