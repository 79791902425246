import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51Pia5YKHEcJeBVEJtNPkj7gj3Z0ZX7qwISdAgZTw2R0Ib1LgRQjOawwLgnXGbP4MGRr5a54la6IrCMHCQQhmqmYl002ZTk5gmI');

function CheckoutButton({ priceId, customerId, onSuccess, onFailure }) {
  const handleCheckout = async () => {
    try {
      const stripe = await stripePromise;

      // Create or retrieve a customer
      const customerResponse = await fetch('https://dashboard.useclarity.app:3001/create-or-retrieve-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: customerId }), // Assuming customerId is the email here
      });

      if (!customerResponse.ok) {
        const errorText = await customerResponse.text();
        console.error('Error response from server:', customerResponse.status, errorText);
        throw new Error('Network response was not ok');
      }

      const { customerId: stripeCustomerId } = await customerResponse.json();

      // Create a Checkout Session
      const checkoutResponse = await fetch('https://dashboard.useclarity.app:3001/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: stripeCustomerId,
          priceId,
        }),
      });

      if (!checkoutResponse.ok) {
        const errorText = await checkoutResponse.text();
        console.error('Error response from server:', checkoutResponse.status, errorText);
        throw new Error('Network response was not ok');
      }

      const { sessionId } = await checkoutResponse.json();

      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        console.error('Stripe Checkout error:', result.error.message);
        if (onFailure) onFailure();
      } else {
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      console.error('Checkout error:', error);
      if (onFailure) onFailure();
    }
  };

  return (
    <button role="link" onClick={handleCheckout}>
      Select Plan
    </button>
  );
}

export default CheckoutButton;
