import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import IssueItem from './IssueItem';

const IssueList = ({ issues = [], title, severityClass, showTop5 }) => {
    if (issues.length === 0) {
        return null;
    }

    // Limit the displayed issues to top 5 if the toggle is on
    const displayedIssues = showTop5 ? issues.slice(0, 5) : issues;

    return (
        <div>
            <h2 className="document-title">{title}</h2>
            {displayedIssues.map((issue, index) => (
                <IssueItem
                    key={index}
                    issue={issue}
                    severityClass={severityClass}
                    isLastItem={index === displayedIssues.length - 1}
                />
            ))}
        </div>
    );
};

IssueList.propTypes = {
    issues: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    severityClass: PropTypes.string.isRequired,
    showTop5: PropTypes.bool.isRequired,  // New prop added
};

export default IssueList;
